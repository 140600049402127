import React, { createContext, useState, useEffect } from 'react';
import APIService from '../APIService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [permissions, setPermissions] = useState([]); // Store user's permissions
    const [user, setUser] = useState(null); // Store user's information
    const [loading, setLoading] = useState(true); // Track loading state for validation

    useEffect(() => {
        // Check for token on app load and validate it
        const token = localStorage.getItem('authToken');
        if (token) {
            validateToken(token);
        } else {
            setLoading(false); // End loading if no token is found
        }
    }, []);

    const validateToken = async (token) => {
        try {
            const userProfile = await APIService.getUserProfile();
            setIsAuthenticated(true);
            setUser(userProfile.user);
            setPermissions(userProfile.permissions || []);
        } catch (error) {
            console.error('Token validation failed:', error.message);
            logout();
        } finally {
            setLoading(false);
        }
    };

    const login = async (username, password) => {
        try {
            const data = await APIService.login(username, password);
            if(data.token && !data.error){
                localStorage.setItem('authToken', data.token);
                setIsAuthenticated(true);
                setUser(data.user);
                setPermissions(data.permissions || []);
            }else{
                return data;
            }
        } catch (error) {
            throw error; // Propagate the error to the caller
        }
    };

    const logout = () => {
        localStorage.removeItem('authToken');
        setIsAuthenticated(false);
        setUser(null);
        setPermissions([]);
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                permissions,
                login,
                logout,
                loading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
