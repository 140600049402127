import React, { useState } from 'react';
import { Modal, Paper, Typography, TextField, Button, MenuItem, FormControl, InputLabel, Select, Box } from '@mui/material';
import {createUser} from '../../../services/adminService';

const AddUserModal = ({ open, onClose, account, t }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [role, setRole] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // To show loading state

  const handleSave = async () => {
    if (password !== retypePassword) {
      setError(t('passwordsDoNotMatch')); // Display error if passwords don't match
      return;
    }
    if (!username || !password || !role) {
      setError(t('allFieldsRequired')); // Check for empty fields
      return;
    }

    setError('');
    setLoading(true); // Set loading to true during API call

    try {
      const payload = {account:account.id,username,password,role};
      const response = await createUser(payload);
      if (response.error) {
        setError(t(response.data.message)); // Show API error message
      } else if(!response.error) {
        // Handle successful save (e.g., show a success message, refresh user list, etc.)
        setError('');
        onClose();
      }
    } catch (apiError) {
      console.error('Error creating user:', apiError);
      setError(t('apiError')); // Generic error message for API failure
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={{ p: 4, maxWidth: 500, margin: 'auto', mt: 10 }}>
        <Typography variant="h6" gutterBottom>{t('addUser')}</Typography>
        <TextField
          label={t('account')}
          disabled
          value={account?.name || ''}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label={t('username')}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label={t('password')}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label={t('retypePassword')}
          type="password"
          value={retypePassword}
          onChange={(e) => setRetypePassword(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>{t('role')}</InputLabel>
          <Select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            label={t('role')}
          >
            <MenuItem value="superuser">{t('superuser')}</MenuItem>
            <MenuItem value="farmer">{t('farmer')}</MenuItem>
            <MenuItem value="packing">{t('packing')}</MenuItem>
          </Select>
        </FormControl>
        {error && (
          <Typography variant="body2" color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleSave}
            disabled={loading} // Disable button during API call
          >
            {loading ? t('saving') : t('save')}
          </Button>
          <Button variant="outlined" fullWidth onClick={onClose} disabled={loading}>
            {t('cancel')}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default AddUserModal;
