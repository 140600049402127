import { Grid, Paper, Typography, Tooltip, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AssessmentIcon from '@mui/icons-material/Assessment';
import WarningIcon from '@mui/icons-material/Warning';

const stats = [
  {
    title: 'controllers',
    value: 10, // Replace with dynamic data
    icon: <DeviceHubIcon sx={{ fontSize: 40, color: 'primary.main' }} />, // MUI default blue
    tooltip: 'Total number of active controllers.',
  },
  {
    title: 'locations',
    value: 5, // Replace with dynamic data
    icon: <LocationOnIcon sx={{ fontSize: 40, color: 'primary.main' }} />, // MUI default blue
    tooltip: 'Number of locations currently monitored.',
  },
  {
    title: 'data',
    value: 25, // Replace with dynamic data
    icon: <AssessmentIcon sx={{ fontSize: 40, color: 'primary.main' }} />, // MUI default blue
    tooltip: 'Data points collected in the last 24 hours.',
  },
  {
    title: 'alerts',
    value: 3, // Replace with dynamic data
    icon: <WarningIcon sx={{ fontSize: 40, color: 'primary.main' }} />, // MUI default blue
    tooltip: 'Number of critical alerts.',
  },
];

// Helper component for the climbing number effect
const ClimbingNumber = ({ target, duration = 1000 }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    const stepTime = duration / target; // Time for each increment
    let current = 0;

    const increment = () => {
      current += 1;
      setCurrentValue((prev) => Math.min(prev + 1, target));
      if (current < target) {
        setTimeout(increment, stepTime);
      }
    };

    increment();

    return () => clearTimeout(increment); // Cleanup on unmount
  }, [target, duration]);

  return <>{currentValue}</>;
};

const Statistics = ({ t }) => (
  <Grid container spacing={3}>
    {stats.map((stat, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <Paper
          elevation={3}
          sx={{
            p: 2,
            height: '100%',
            backgroundColor: 'background.paper', // MUI default paper color
            borderRadius: '8px',
            transition: 'transform 0.2s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Subtle shadow
            },
          }}
        >
          <Tooltip title={t(stat.tooltip)}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              {stat.icon}
              <Typography variant="h6" sx={{ flexGrow: 1, textTransform: 'capitalize' }}>
                {t(stat.title)}
              </Typography>
            </Box>
          </Tooltip>
          <Typography variant="h4" sx={{ mt: 1 }}>
            <ClimbingNumber target={stat.value} />
          </Typography>
        </Paper>
      </Grid>
    ))}
  </Grid>
);

export default Statistics;
