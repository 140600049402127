import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getAccountInvoices } from '../../services/authService'; // Ensure this is the correct service path
import dayjs from 'dayjs'; // For date formatting

const PaymentsPage = ({ direction }) => {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50); // Default to 50 rows per page
  const [searchTerm, setSearchTerm] = useState('');
  const [totalInvoices, setTotalInvoices] = useState(0); // To track total invoices for pagination
  const [loading, setLoading] = useState(true); // To handle loading state

  // Alignment based on direction
  const align = direction === 'rtl' ? 'right' : 'left';

  // Fetch invoices using the API
  useEffect(() => {
    const fetchInvoices = async () => {
      setLoading(true);
      try {
        const response = await getAccountInvoices({ page: page + 1, limit: rowsPerPage }); // Adjust for 1-based pagination
        setInvoices(response.invoices || []);
        setTotalInvoices(response.pagination?.totalItems || 0);
      } catch (error) {
        console.error('Failed to fetch invoices:', error);
        setInvoices([]);
        setTotalInvoices(0);
      } finally {
        setLoading(false);
      }
    };
    fetchInvoices();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredInvoices = invoices.filter((invoice) =>
    invoice.invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const markAsPaid = (id) => {
    const updatedInvoices = invoices.map((inv) =>
      inv.id === id ? { ...inv, status: t('waitingForConfirmation') } : inv
    );
    setInvoices(updatedInvoices);
  };

  const viewInvoice = (id) => {
    console.log(`View invoice ${id}`);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper style={{ padding: 20 }}>
              <Typography variant="h5" gutterBottom align={align}>
                {t('payment')}
              </Typography>

              {/* Search Field */}
              <TextField
                label={t('searchInvoice')}
                variant="outlined"
                size="small"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputLabelProps={{
                  className: direction === 'rtl' ? 'custom-label-rtl' : '',
                }}
                inputProps={{
                  style: { textAlign: align },
                }}
              />

              <TableContainer component={Paper} style={{ marginTop: 20 }}>
                <Table>
                  {/* Table Header */}
                  <TableHead>
                    <TableRow>
                      <TableCell align={align}>{t('invoiceNumber')}</TableCell>
                      <TableCell align={align}>{t('amount')}</TableCell>
                      <TableCell align={align}>{t('status')}</TableCell>
                      <TableCell align={align}>{t('createdAt')}</TableCell>
                      <TableCell align={align}>{t('actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* Table Body */}
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          {t('loading')}
                        </TableCell>
                      </TableRow>
                    ) : invoices.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          {t('noInvoicesFound')}
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredInvoices.map((invoice) => (
                        <TableRow key={invoice.id}>
                          <TableCell align={align}>{invoice.invoiceNumber}</TableCell>
                          <TableCell align={align}>${invoice.amount}</TableCell>
                          <TableCell align={align}>{t(invoice.status)}</TableCell>
                          <TableCell align={align}>
                            {dayjs(invoice.createdAt).format('DD-MM-YYYY')}
                          </TableCell>
                          <TableCell align={align}>
                            {invoice.status === 'unpaid' && (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => markAsPaid(invoice.id)}
                              >
                                {t('markAsPaid')}
                              </Button>
                            )}
                            <Button
                              style={{ marginRight: '3%' }}
                              variant="outlined"
                              color="primary"
                              onClick={() => viewInvoice(invoice.id)}
                            >
                              {t('viewInvoice')}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination */}
              {!loading && invoices.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={totalInvoices}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PaymentsPage;
