import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  Select,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const EditPortModal = ({ open, onClose, port, t, direction}) => {
  // State for minValue, maxValue, checkbox, and time duration
  const [minValue, setMinValue] = useState(port?.minValue || 0);
  const [maxValue, setMaxValue] = useState(port?.maxValue || 100);
  const [notifyMismatch, setNotifyMismatch] = useState(false);
  const [duration, setDuration] = useState(1); // Default to 1
  const [durationUnit, setDurationUnit] = useState('seconds'); // Default to seconds

  const handleSave = () => {
    // Collect the updated values
    const updatedPort = {
      ...port,
      minValue,
      maxValue,
      notifyMismatch,
      notificationDuration: notifyMismatch ? { value: duration, unit: durationUnit } : null,
    };

    // TODO: Add API call here to save updatedPort data to the cloud
    console.log('Port data to save:', updatedPort);

    // Close the modal
    onClose();
  };

  if (!port) {
    // Graceful handling if port is null or undefined
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: 400,
          maxWidth: '90%',
          bgcolor: 'background.paper',
          p: 4,
          borderRadius: 2,
          boxShadow: 24,
          textAlign: 'center',
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <Close />
        </IconButton>

        {/* Modal Title */}
        <Typography variant="h6" gutterBottom>
          {t('editPort')}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {`${t('portUID')}: ${port.uid || t('unknown')}`}
        </Typography>
        <Divider sx={{ my: 2 }} />

        {/* Editable Fields */}
        <Box sx={{ mb: 3 }}>
          {/* Protocol Dropdown (Disabled) */}
          <Typography variant="body2" gutterBottom>
            {t('protocol')}
          </Typography>
          <Select
            value={port.protocol || ''}
            fullWidth
            size="small"
            displayEmpty
            sx={{ mb: 2 }}
            disabled
          >
            <MenuItem value={port.protocol || ''}>
              {t(port.protocol || 'unknown')}
            </MenuItem>
          </Select>

          {/* Sensor Count Input (Centered Text) */}
          <Typography variant="body2" gutterBottom>
            {t('sensorCount')}
          </Typography>
          <TextField
            type="number"
            value={port.sensor_count || ''}
            fullWidth
            size="small"
            InputProps={{
              style: { textAlign: 'center' },
              readOnly: true,
            }}
          />

          {/* Min and Max Values */}
          <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
            {t('minMaxValues')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            <TextField
              label={t('minValue')}
              type="number"
              value={minValue}
              onChange={(e) => setMinValue(Number(e.target.value))}
              size="small"
              sx={{ flex: 1 }}
            />
            <TextField
              label={t('maxValue')}
              type="number"
              value={maxValue}
              onChange={(e) => setMaxValue(Number(e.target.value))}
              size="small"
              sx={{ flex: 1 }}
            />
          </Box>

          {/* Notify Mismatch Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={notifyMismatch}
                onChange={(e) => setNotifyMismatch(e.target.checked)}
              />
            }
            label={t('notifyOnMismatch')}
            sx={{ mt: 2 }}
          />

          {/* Duration Input */}
          {notifyMismatch && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" gutterBottom>
                {t('notifyDuration')}
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  label={t('durationValue')}
                  type="number"
                  value={duration}
                  onChange={(e) => setDuration(Number(e.target.value))}
                  size="small"
                  sx={{ flex: 1 }}
                />
                <Select
                  value={durationUnit}
                  onChange={(e) => setDurationUnit(e.target.value)}
                  size="small"
                  sx={{ flex: 1 }}
                >
                  <MenuItem value="seconds">{t('seconds')}</MenuItem>
                  <MenuItem value="minutes">{t('minutes')}</MenuItem>
                  <MenuItem value="hours">{t('hours')}</MenuItem>
                </Select>
              </Box>
            </Box>
          )}
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Action Buttons */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            mt: 3,
          }}
        >
          <Button variant="contained" onClick={handleSave}>
            {t('save')}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditPortModal;
