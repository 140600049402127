import React from 'react';
import { Modal, Paper, Typography, TextField, Button, Box } from '@mui/material';

const EditAccountModal = ({ open, onClose, onSave, account }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={{ p: 4, maxWidth: 500, margin: 'auto', mt: 10 }}>
        <Typography variant="h6" gutterBottom>Edit Account</Typography>
        <TextField
          label="Name"
          fullWidth
          defaultValue={account?.name}
          sx={{ mb: 2 }}
        />
        <TextField
          label="TM Tag"
          fullWidth
          defaultValue={account?.TMTag}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Latitude"
          fullWidth
          defaultValue={account?.latitude}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Longitude"
          fullWidth
          defaultValue={account?.longitude}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="contained" onClick={onSave}>Save</Button>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default EditAccountModal;
