import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Modal,
  TextField,
  Tabs,
  Tab,
  CircularProgress,
  InputAdornment,
} from '@mui/material';
import {
  Add,
  Edit,
  Delete,
  LocationOn,
  SensorDoor,
  Lock,
  AttachMoney,
  DataUsage,
  Search,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import PermissionManager from './PermissionManager';
import APIService from '../../APIService';
import AddAccountModal from './modals/AddAccountModal';
import EditAccountModal from './modals/EditAccountModal';
import AddUserModal from './modals/AddUserModal';
import EditUserModal from './modals/EditUserModal';

const AdminDashboard = () => {
  const { t } = useTranslation();
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUserModalOpen, setUserModalOpen] = useState(false);
  const [isEditAccountModalOpen, setEditAccountModalOpen] = useState(false);
  const [isEditUserModalOpen, setEditUserModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [userDetailsTab, setUserDetailsTab] = useState(0);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async (query = '') => {
    setIsLoadingAccounts(true);
    try {
      const response = await APIService.getAllAccounts(query);
      if (!response.error) {
        setAccounts(response.accounts || []);
      }
    } catch (error) {
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  const fetchUsers = async (query = '') => {
    setIsLoadingUsers(true);
    try {
      const response = await APIService.getAllUsersUnderAccount(selectedAccount?.id, query);
      setUsers(response.users || []);
    } catch (error) {
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const handleAccountSelect = async (account) => {
    setSelectedAccount(account);
    setUsers([]);
    setSelectedUser(null);
    await fetchUsers();
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleAddAccount = async (newAccount) => {
    try {
      const response = await APIService.createAccount(newAccount);
      if (!response.error) {
        setAccounts((prev) => [...prev, response.account]); // Update state directly
        setModalOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleAddUser = async (newUser) => {
    try {
      const response = await APIService.createUser(newUser);
      if (!response.error) {
        setUsers((prev) => [...prev, response.user]); // Update state directly
        setUserModalOpen(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  useEffect(() => {
    if (selectedUser) {
      setUserDetailsTab(0); // Reset tabs when a new user is selected
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedTab === 1 && selectedAccount) {
      fetchUsers();
    }
  }, [selectedTab, selectedAccount]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.trim()) {
      fetchAccounts(value.trim());
    } else {
      fetchAccounts();
    }
  };

  const handleUserSearchChange = (event) => {
    const value = event.target.value;
    setUserSearchTerm(value);
    if (value.trim()) {
      fetchUsers(value.trim());
    } else {
      fetchUsers();
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleUserDetailsTabChange = (event, newValue) => {
    setUserDetailsTab(newValue);
  };

  const columnsAccounts = [
    { field: 'id', headerName: t('id'), width: 100 },
    { field: 'name', headerName: t('name'), flex: 1 },
    { field: 'crmId', headerName: t('crmId'), flex: 1 },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 150,
      renderCell: (params) => (
        <Box>
          <Button
            onClick={() => setEditAccountModalOpen(true)}
            startIcon={<Edit />}
          >
            {t('edit')}
          </Button>
          <Button
            onClick={() => console.log('Delete Account')}
            color="error"
            startIcon={<Delete />}
          >
            {t('delete')}
          </Button>
        </Box>
      ),
    },
  ];

  const columnsUsers = [
    { field: 'id', headerName: t('id'), width: 100 },
    { field: 'username', headerName: t('username'), flex: 1 },
    { field: 'role', headerName: t('role'), flex: 1 },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 150,
      renderCell: (params) => (
        <Box>
          <Button
            onClick={() => setEditUserModalOpen(true)}
            startIcon={<Edit />}
          >
            {t('edit')}
          </Button>
          <Button
            onClick={() => console.log('Delete User')}
            color="error"
            startIcon={<Delete />}
          >
            {t('delete')}
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Container maxWidth="lg">
      <Paper sx={{ p: 2, mb: 2 }}>
        <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
          <Tab label={t('accounts')} />
          <Tab label={t('users')} disabled={!selectedAccount} />
        </Tabs>
      </Paper>

      {selectedTab === 0 && (
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            {t('accounts')}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            placeholder={t('searchAccount')}
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setModalOpen(true)}
            sx={{ mb: 2 }}
          >
            {t('addAccount')}
          </Button>
          {isLoadingAccounts ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              key={accounts.length} // Ensures re-render on state change
              rows={accounts}
              columns={columnsAccounts}
              autoHeight
              pageSize={5}
              rowsPerPageOptions={[5]}
              onRowClick={(row) => handleAccountSelect(row.row)}
            />
          )}
        </Paper>
      )}

      {selectedTab === 1 && selectedAccount && (
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            {`${t('usersUnder')} ${selectedAccount.name}`}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            placeholder={t('searchUser')}
            value={userSearchTerm}
            onChange={handleUserSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setUserModalOpen(true)}
            sx={{ mb: 2 }}
          >
            {t('addUser')}
          </Button>
          {isLoadingUsers ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              rows={users}
              columns={columnsUsers}
              autoHeight
              pageSize={5}
              rowsPerPageOptions={[5]}
              onRowClick={(row) => handleUserSelect(row.row)}
            />
          )}
        </Paper>
      )}

      {selectedTab === 1 && selectedUser && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Tabs value={userDetailsTab} onChange={handleUserDetailsTabChange} variant="fullWidth">
            <Tab label={t('permissions')} />
            <Tab label={t('locations')} />
            <Tab label={t('controllers')} />
            <Tab label={t('sensors')} />
            <Tab label={t('dataUsage')} />
            <Tab label={t('billing')} />
          </Tabs>
          <Box>
            {userDetailsTab === 0 && (
              <PermissionManager
                key={selectedUser?.id}
                userId={selectedUser?.id}
                userPermissions={selectedUser?.permissions}
              />
            )}
            {userDetailsTab === 1 && <Typography>{t('locationsContent')}</Typography>}
            {userDetailsTab === 2 && <Typography>{t('controllersContent')}</Typography>}
            {userDetailsTab === 3 && <Typography>{t('sensorsContent')}</Typography>}
            {userDetailsTab === 4 && <Typography>{t('dataUsageContent')}</Typography>}
            {userDetailsTab === 5 && <Typography>{t('billingContent')}</Typography>}
          </Box>
        </Paper>
      )}

      <AddAccountModal
        t={t}
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleAddAccount}
      />
      <EditAccountModal t={t} open={isEditAccountModalOpen} onClose={() => setEditAccountModalOpen(false)} account={selectedAccount} />
      <AddUserModal
        t={t}
        account={selectedAccount}
        open={isUserModalOpen}
        onClose={() => setUserModalOpen(false)}
        onSave={handleAddUser}
      />
      <EditUserModal t={t} open={isEditUserModalOpen} onClose={() => setEditUserModalOpen(false)} user={selectedUser} />
    </Container>
  );
};

export default AdminDashboard;
