import api from './APIService';

export const login = async (username, password) => {
  const response = await api.post('/auth/login', { username, password });
  return response.data;
};

export const getUserProfile = async () => {
  const response = await api.get('/auth/me');
  return response.data;
};

export const getAccountInvoices = async () => {
  const response = await api.get('/auth/me/invoices');
  return response.data;
};


export const updateUserProfile = async (profileData) => {
  const response = await api.put('/profile', profileData);
  return response.data;
};

export const getAllPermissions = async () => {
  const response = await api.get('/auth/permissions');
  return response.data;
};
