import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import PalletModal from '../modals/PalletModal'; // Import the modal component
import { getProducePallets, destroyProductPallet} from '../../../services/produceService';

const Pallets = ({ direction }) => {
  const { t } = useTranslation();
  const [palletData, setPalletData] = useState([]);
  const [currentPallet, setCurrentPallet] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPallets = async () => {
      try {
        setLoading(true);
        const response = await getProducePallets();
        if (response.pallets) {
          setPalletData(response.pallets || []);
        }
      } catch (error) {
        console.error('Error fetching pallets:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPallets();
  }, []);

  const handleAddPallet = () => {
    setCurrentPallet(null);
    setIsModalOpen(true);
  };

  const handleEditPallet = (pallet) => {
    setCurrentPallet(pallet);
    setIsModalOpen(true);
  };

  const handleDeletePallet = async (palletId) => {
    const response = await destroyProductPallet(palletId);
    if(!response.error){
        setPalletData((prevData) => prevData.filter((pallet) => pallet.id !== palletId));
    }else{
        // TODO: handle error
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentPallet(null);
  };

  const handleSavePallet = () => {
    if (currentPallet?.id) {
      setPalletData((prevData) =>
        prevData.map((pallet) => (pallet.id === currentPallet.id ? currentPallet : pallet))
      );
    } else {
      setPalletData((prevData) => [
        ...prevData,
        { ...currentPallet, id: prevData.length + 1 },
      ]);
    }
    handleModalClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const csvHeaders = [
    { label: t('id'), key: 'id' },
    { label: t('name'), key: 'name' },
    { label: t('weightKG'), key: 'weight' },
    { label: t('widthCM'), key: 'width' },
    { label: t('lengthCM'), key: 'length' },
  ];

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t('palletManagement')}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleAddPallet} sx={{ mb: 2 }}>
          {t('addPallet')}
        </Button>
        <Button variant="contained" color="secondary" sx={{ mb: 2, ml: 2 }}>
          <CSVLink
            data={palletData}
            headers={csvHeaders}
            filename={`pallets.csv`}
            style={{ textDecoration: 'none', color: 'white' }}
          >
            {t('exportCSV')}
          </CSVLink>
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('id')}</TableCell>
                <TableCell>{t('name')}</TableCell>
                <TableCell>{t('weightKG')}</TableCell>
                <TableCell>{t('widthCM')}</TableCell>
                <TableCell>{t('lengthCM')}</TableCell>
                <TableCell>{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {palletData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((pallet) => (
                  <TableRow key={pallet.id}>
                    <TableCell>{pallet.id}</TableCell>
                    <TableCell>{pallet.name}</TableCell>
                    <TableCell>{pallet.weight}</TableCell>
                    <TableCell>{pallet.width}</TableCell>
                    <TableCell>{pallet.length}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleEditPallet(pallet)}
                        sx={{ mr: 1 }}
                      >
                        {t('edit')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeletePallet(pallet.id)}
                      >
                        {t('delete')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={palletData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <PalletModal
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        currentPallet={currentPallet}
        setCurrentPallet={setCurrentPallet}
        handleSave={handleSavePallet}
        direction={direction}
        t={t}
      />
    </Container>
  );
};

export default Pallets;
