import React from 'react';
import { Modal, Paper, Typography, TextField, Box, Button } from '@mui/material';
import { putProduceStage, postProduceStage } from '../../../services/produceService';

const StageModal = ({
  isOpen,
  handleClose,
  currentStage,
  setCurrentStage,
  handleSave,
  direction,
  t,
}) => {
  const handleSaveWithAPI = async () => {
    try {
      // Determine whether to update or create
      if (currentStage?.id) {
        // Update existing stage
        await postProduceStage(currentStage);
      } else {
        // Create new stage
        await putProduceStage(currentStage);
      }
      // Call the handleSave callback to update UI state
      handleSave();
    } catch (error) {
      console.error('Error saving stage data:', error);
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Paper sx={{ p: 4, width: '400px', margin: 'auto', mt: 10 }}>
        <Typography variant="h6">
          {currentStage ? t('editStage') : t('addStage')}
        </Typography>
        <TextField
          label={t('name')}
          fullWidth
          margin="normal"
          value={currentStage?.name || ''}
          onChange={(e) =>
            setCurrentStage((prev) => ({ ...prev, name: e.target.value }))
          }
          InputLabelProps={{
            className: direction === 'rtl' ? 'custom-label-rtl' : '',
          }}
        />
        <TextField
          label={t('description')}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={currentStage?.description || ''}
          onChange={(e) =>
            setCurrentStage((prev) => ({ ...prev, description: e.target.value }))
          }
          InputLabelProps={{
            className: direction === 'rtl' ? 'custom-label-rtl' : '',
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSaveWithAPI}>
            {t('save')}
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            {t('cancel')}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default StageModal;
