import React, { useState, useEffect } from 'react';
import { Modal, Paper, Box, Typography, TextField, Button, Select, MenuItem } from '@mui/material';
import { postProduceBarcode, putProduceBarcode } from '../../../services/produceService';

const BarcodeModal = ({ isOpen, barcode, onClose, onSave, direction, t, categories, locations, stages, pallets, boxes }) => {
  const [barcodeData, setBarcodeData] = useState(barcode || {});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setBarcodeData(barcode || {});
  }, [barcode]);

  const handleChange = (field, value) => {
    setBarcodeData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      if (barcodeData.id) {
        // Update existing barcode
        await postProduceBarcode(barcodeData);
      } else {
        // Create new barcode
        await putProduceBarcode(barcodeData);
      }
      onSave(barcodeData); // Update the parent component's state
    } catch (error) {
      console.error('Error saving barcode:', error);
    } finally {
      setIsSaving(false);
      onClose();
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Paper sx={{ p: 4, width: 400, margin: 'auto', mt: 10 }}>
        <Typography variant="h6">
          {barcode ? t('editBarcode') : t('addBarcode')}
        </Typography>
        <TextField
          label={t('barcode')}
          fullWidth
          margin="normal"
          value={barcodeData.barcode || ''}
          onChange={(e) => handleChange('barcode', e.target.value)}
          disabled={!!barcodeData.id} // Disable if editing (barcodeData.id exists)
          InputLabelProps={{
            className: direction === 'rtl' ? 'custom-label-rtl' : '',
          }}
        />
        <TextField
          label={t('weightKG')}
          fullWidth
          margin="normal"
          type="number"
          value={barcodeData.weight || ''}
          onChange={(e) => handleChange('weight', e.target.value)}
          InputLabelProps={{
            className: direction === 'rtl' ? 'custom-label-rtl' : '',
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Select
            value={barcodeData.location || ''}
            onChange={(e) => handleChange('location', e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              {t('selectLocation')}
            </MenuItem>
            {locations.map((location) => (
              <MenuItem key={location.id} value={location.id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={barcodeData.category || ''}
            onChange={(e) => handleChange('category', e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              {t('selectCategory')}
            </MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={barcodeData.stage || ''}
            onChange={(e) => handleChange('stage', e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              {t('selectStage')}
            </MenuItem>
            {stages.map((stage) => (
              <MenuItem key={stage.id} value={stage.id}>
                {stage.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={barcodeData.box || ''}
            onChange={(e) => handleChange('box', e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              {t('selectBox')}
            </MenuItem>
            {boxes.map((box) => (
              <MenuItem key={box.id} value={box.id}>
                {box.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            label={t('boxesCount')}
            fullWidth
            margin="normal"
            type="number"
            value={barcodeData.boxesCount || ''}
            onChange={(e) => handleChange('boxesCount', e.target.value)}
            InputLabelProps={{
              className: direction === 'rtl' ? 'custom-label-rtl' : '',
            }}
          />
          <Select
            value={barcodeData.pallet || ''}
            onChange={(e) => handleChange('pallet', e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="" disabled>
              {t('selectPallet')}
            </MenuItem>
            {pallets.map((pallet) => (
              <MenuItem key={pallet.id} value={pallet.id}>
                {pallet.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaving}>
            {isSaving ? t('saving') : t('save')}
          </Button>
          <Button variant="outlined" onClick={onClose} disabled={isSaving}>
            {t('cancel')}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default BarcodeModal;
