import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { getProduceBoxes, destroyProduceBox } from '../../../services/produceService';
import BoxModal from '../modals/BoxModal'; // Import the modal component

const Boxes = ({ direction }) => {
  const { t } = useTranslation();

  const [boxData, setBoxData] = useState([]);
  const [currentBox, setCurrentBox] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchBoxes = async () => {
      try {
        const response = await getProduceBoxes(); // Call the API method
        if (response.boxes) {
          setBoxData(response.boxes || []); // Set the response to state
        }
      } catch (error) {
        console.error('Error fetching boxes:', error);
      }
    };

    fetchBoxes();
  }, []);

  const handleAddBox = () => {
    setCurrentBox(null);
    setIsModalOpen(true);
  };

  const handleEditBox = (box) => {
    setCurrentBox(box);
    setIsModalOpen(true);
  };

  const handleDeleteBox = async (boxId) => {
    const deletedBox = await destroyProduceBox(boxId);
    if(!deletedBox.error){
        setBoxData((prevData) => prevData.filter((box) => box.id !== boxId));
    }else{
        // TODO: handle error
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentBox(null);
  };

  const handleSaveBox = () => {
    if (currentBox?.id) {
      setBoxData((prevData) =>
        prevData.map((box) => (box.id === currentBox.id ? currentBox : box))
      );
    } else {
      setBoxData((prevData) => [
        ...prevData,
        { ...currentBox, id: prevData.length + 1 },
      ]);
    }
    handleModalClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const csvHeaders = [
    { label: t('id'), key: 'id' },
    { label: t('name'), key: 'name' },
    { label: t('weightKG'), key: 'weight' },
    { label: t('widthCM'), key: 'width' },
    { label: t('heightCM'), key: 'height' },
    { label: t('lengthCM'), key: 'length' },
    { label: t('color'), key: 'color' },
    { label: t('manufacturer'), key: 'manufacturer' },
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t('boxManagement')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddBox}
          sx={{ mb: 2 }}
        >
          {t('addBox')}
        </Button>
        <Button variant="contained" color="secondary" sx={{ mb: 2, ml: 2 }}>
          <CSVLink
            data={boxData}
            headers={csvHeaders}
            filename={`boxes.csv`}
            style={{ textDecoration: 'none', color: 'white' }}
          >
            {t('exportCSV')}
          </CSVLink>
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('id')}</TableCell>
                <TableCell>{t('name')}</TableCell>
                <TableCell>{t('weightKG')}</TableCell>
                <TableCell>{t('widthCM')}</TableCell>
                <TableCell>{t('heightCM')}</TableCell>
                <TableCell>{t('lengthCM')}</TableCell>
                <TableCell>{t('color')}</TableCell>
                <TableCell>{t('manufacturer')}</TableCell>
                <TableCell>{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {boxData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((box) => (
                  <TableRow key={box.id}>
                    <TableCell>{box.id}</TableCell>
                    <TableCell>{box.name}</TableCell>
                    <TableCell>{box.weight}</TableCell>
                    <TableCell>{box.width}</TableCell>
                    <TableCell>{box.height}</TableCell>
                    <TableCell>{box.length}</TableCell>
                    <TableCell>{box.color || t('notAvailable')}</TableCell>
                    <TableCell>
                      {box.manufacturer || t('notAvailable')}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleEditBox(box)}
                        sx={{ mr: 1 }}
                      >
                        {t('edit')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteBox(box.id)}
                      >
                        {t('delete')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={boxData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <BoxModal
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        currentBox={currentBox}
        setCurrentBox={setCurrentBox}
        handleSave={handleSaveBox}
        direction={direction}
        t={t}
      />
    </Container>
  );
};

export default Boxes;
