import React from 'react';
import {
    Modal,
    Paper,
    Typography,
    TextField,
    Button,
    Select,
    MenuItem,
  } from '@mui/material';

const BindLocationModal = ({ open, onClose, device, t, direction }) => {
  return (
    <Modal open={open} onClose={onClose}>
        <Paper sx={{ p: 4, maxWidth: 500, margin: 'auto', mt: 10 }}>
        
        </Paper>
    </Modal>
  );
};

export default BindLocationModal;