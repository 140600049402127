import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Divider,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routesConfig from '../../configs/routesConfig'; // Import the routesConfig
import logo from '../../assets/images/logo_white.png';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;

const SideNav = ({ mobileOpen, handleDrawerToggle, direction, permissions = [], userRole }) => {
  const isRTL = direction === 'rtl';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 600px)'); // Adjust this breakpoint as needed

  const drawer = (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          p: 2,
          textAlign: isRTL ? 'right' : 'left',
        }}
      >
        <Box
          component="img"
          sx={{
            marginTop: isMobile ? 8 : 0,
            width: 180, // Adjust the width for mobile
          }}
          alt="Logo"
          src={logo}
        />
        {isMobile && (
          <IconButton onClick={handleDrawerToggle}>
            <MenuIcon style={{ color: 'white' }} />
          </IconButton>
        )}
      </Box>
      <Divider />
      {Object.keys(routesConfig).map((categoryKey) => {
        const category = routesConfig[categoryKey];

        const filteredItems = (category.items || []).filter((item) => {
          const permission = permissions.find((perm) => perm.route === item.route);
          return permission && (permission.read || permission.write || permission.delete);
        });

        if (filteredItems.length > 0) {
          return (
            <div key={categoryKey}>
              <Typography
                variant="overline"
                sx={{
                  color: 'white',
                  pl: isRTL ? 0 : 2,
                  pr: isRTL ? 2 : 0,
                  textAlign: isRTL ? 'right' : 'left',
                }}
              >
                {t(category.name)}
              </Typography>
              <List sx={{ direction: isRTL ? 'rtl' : 'ltr' }}>
                {filteredItems.map((item) => (
                  <ListItem
                    button={true}
                    key={item.text}
                    onClick={() => navigate(item.route)}
                    sx={{
                      textAlign: isRTL ? 'right' : 'left',
                      paddingY: 2,
                      paddingX: 3,
                      width: '100%',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      },
                      '&:active': {
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                      },
                    }}
                  >
                    <ListItemIcon sx={{ textAlign: isRTL ? 'right' : 'left', color: 'white' }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{
                            textAlign: isRTL ? 'right' : 'left',
                            color: 'white',
                            fontWeight: 'regular',
                          }}
                        >
                          {t(item.text)}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
              <Divider />
            </div>
          );
        }
        return null;
      })}
    </div>
  );

  return (
    <Box component="nav">
      {/* Temporary Drawer for mobile */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        anchor={direction === 'rtl' ? 'right' : 'left'}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#1e90ff',
            color: 'white',
            flexDirection: 'column',
            overflowY: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        }}
      >
        {drawer}
      </Drawer>

      {/* Permanent Drawer for desktop */}
      <Drawer
        variant="permanent"
        anchor={direction === 'rtl' ? 'right' : 'left'}
        open
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#1e90ff',
            color: 'white',
            overflowY: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default SideNav;
