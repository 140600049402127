import React from 'react';
import { Modal, Paper, Typography, TextField, Button, Box } from '@mui/material';

const EditUserModal = ({ open, onClose, onSave, user }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={{ p: 4, maxWidth: 500, margin: 'auto', mt: 10 }}>
        <Typography variant="h6" gutterBottom>Edit User</Typography>
        <TextField
          label="Username"
          fullWidth
          defaultValue={user?.username}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Email"
          fullWidth
          defaultValue={user?.email}
          sx={{ mb: 2 }}
        />
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button variant="contained" onClick={onSave}>Save</Button>
          <Button variant="outlined" onClick={onClose}>Cancel</Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default EditUserModal;
