import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import APIService from '../../APIService';

const PermissionManager = ({ userId, userPermissions, onSave }) => {
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState(userPermissions || {});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const data = await APIService.getAllUserPermissions(userId);
        setPermissions(data.permissions); // Assuming the API returns permissions grouped by category
      } catch (error) {
        console.error('Failed to fetch permissions:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, []);

  const handleCheckboxChange = (category, route, permissionType) => {
    setPermissions((prev) => ({
      ...prev,
      [category]: prev[category].map((item) =>
        item.route === route
          ? { ...item, [permissionType]: !item[permissionType] }
          : item
      ),
    }));
  };

  const renderTableRows = (category) =>
    permissions[category].map((item) => (
      <TableRow key={item.route}>
        <TableCell>{item.route}</TableCell>
        <TableCell align="center">
          <Checkbox
            checked={item.read}
            onChange={() => handleCheckboxChange(category, item.route, 'read')}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            checked={item.write}
            onChange={() => handleCheckboxChange(category, item.route, 'write')}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            checked={item.delete}
            onChange={() => handleCheckboxChange(category, item.route, 'delete')}
          />
        </TableCell>
      </TableRow>
    ));

  if (loading) {
    return <Typography>{t('loadingPermissions')}</Typography>;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        {t('managePermissions')}
      </Typography>
      <Grid container spacing={2}>
        {Object.keys(permissions).map((category) => (
          <Grid item xs={12} key={category}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">{t(category)}</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('route')}</TableCell>
                      <TableCell align="center">{t('read')}</TableCell>
                      <TableCell align="center">{t('write')}</TableCell>
                      <TableCell align="center">{t('delete')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderTableRows(category)}</TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Button
          variant="contained"
          onClick={() => onSave(permissions)}
        >
          {t('savePermissions')}
        </Button>
      </Box>
    </Box>
  );
};

export default PermissionManager;
