import React from 'react';
import { Modal, Paper, Typography, TextField, Box, Button } from '@mui/material';
import { putProduceCategory, postProduceCategory } from '../../../services/produceService';

const CategoryModal = ({
  isOpen,
  handleClose,
  currentCategory,
  setCurrentCategory,
  handleSave,
  direction,
  t,
}) => {
  const handleSaveWithAPI = async () => {
    try {
      // Determine whether to update or create
      if (currentCategory?.id) {
        // Update existing category
        await postProduceCategory(currentCategory);
      } else {
        // Create new category
        await putProduceCategory(currentCategory);
      }
      // Call the handleSave callback to update UI state
      handleSave();
    } catch (error) {
      console.error('Error saving category data:', error);
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Paper sx={{ p: 4, width: '400px', margin: 'auto', mt: 10 }}>
        <Typography variant="h6">
          {currentCategory ? t('editCategory') : t('addCategory')}
        </Typography>
        <TextField
          label={t('name')}
          fullWidth
          margin="normal"
          value={currentCategory?.name || ''}
          onChange={(e) =>
            setCurrentCategory((prev) => ({ ...prev, name: e.target.value }))
          }
          InputLabelProps={{
            className: direction === 'rtl' ? 'custom-label-rtl' : '',
          }}
        />
        <TextField
          label={t('description')}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={currentCategory?.description || ''}
          onChange={(e) =>
            setCurrentCategory((prev) => ({ ...prev, description: e.target.value }))
          }
          InputLabelProps={{
            className: direction === 'rtl' ? 'custom-label-rtl' : '',
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSaveWithAPI}>
            {t('save')}
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            {t('cancel')}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default CategoryModal;
