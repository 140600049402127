import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TablePagination,
  CircularProgress,
  Checkbox,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import BarcodeModal from './modals/BarcodeModal';
import {
  getProduceBarcodes,
  getProduceCategories,
  getProduceStages,
  getProduceBoxes,
  getProducePallets,
} from '../../services/produceService';
import { getLocations } from '../../services/locationService';

const Barcodes = ({ direction }) => {
  const { t } = useTranslation();
  const [barcodeData, setBarcodeData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pallets, setPallets] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [stages, setStages] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filteredBarcodeData, setFilteredBarcodeData] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBarcode, setSelectedBarcode] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [
          categoriesResponse,
          locationsResponse,
          barcodesResponse,
          stagesResponse,
          boxesResponse,
          palletsResponse,
        ] = await Promise.all([
          getProduceCategories(),
          getLocations(),
          getProduceBarcodes(),
          getProduceStages(),
          getProduceBoxes(),
          getProducePallets(),
        ]);

        setCategories(categoriesResponse.categories || []);
        setLocations(locationsResponse.locations || []);
        setBarcodeData(barcodesResponse.produce || []);
        setStages(stagesResponse.stages || []);
        setBoxes(boxesResponse.boxes || []);
        setPallets(palletsResponse.pallets || []);
        setFilteredBarcodeData(barcodesResponse.produce || []);

        // Calculate statistics
        const totalWeightToday = barcodesResponse.produce.reduce(
          (acc, cur) => acc + cur.netWeight,
          0
        );
        const totalGrossWeightToday = barcodesResponse.produce.reduce(
          (acc, cur) => acc + cur.grossWeight,
          0
        );
        setStatistics({
          totalToday: barcodesResponse.produce.length,
          totalGrossWeightToday,
          totalNetWeightToday: totalWeightToday,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const openModal = (barcode = null) => {
    setSelectedBarcode(barcode);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBarcode(null);
  };

  const handleDeleteBarcode = (id) => {
    setBarcodeData((prevData) => prevData.filter((item) => item.id !== id));
    setFilteredBarcodeData((prevData) => prevData.filter((item) => item.id !== id));
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;

    let filteredData = barcodeData;
    if (name === 'category') {
      setSelectedCategory(value);
      filteredData = barcodeData.filter((item) => item.category === value || value === '');
    }
    if (name === 'location') {
      setSelectedLocation(value);
      filteredData = barcodeData.filter((item) => item.location === value || value === '');
    }

    setFilteredBarcodeData(filteredData);
  };

  const handleDateChange = (event) => {
    const selectedDateValue = event.target.value;
    setSelectedDate(selectedDateValue);

    const filteredData = barcodeData.filter(
      (item) =>
        dayjs(item.createdAt).format('YYYY-MM-DD') === selectedDateValue || selectedDateValue === ''
    );
    setFilteredBarcodeData(filteredData);
  };

  if (loading) {
    return (
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t('barcodeScanning')}
        </Typography>

        <Grid container spacing={3}>
          {/* Date and Statistics */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">{t('selectDate')}</Typography>
              <TextField
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
                fullWidth
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6">{t('statistics')}</Typography>
              <Typography>{t('totalScannedToday')}: {statistics.totalToday}</Typography>
              <Typography>
                {t('totalGrossWeightToday')}: {statistics.totalGrossWeightToday} kg
              </Typography>
              <Typography>
                {t('totalNetWeightToday')}: {statistics.totalNetWeightToday} kg
              </Typography>
            </Paper>
          </Grid>

          {/* Filters */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, mb: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <FormControl sx={{ minWidth: '150px' }}>
                  <InputLabel>{t('category')}</InputLabel>
                  <Select
                    name="category"
                    value={selectedCategory}
                    onChange={handleFilterChange}
                  >
                    <MenuItem value="">{t('all')}</MenuItem>
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ minWidth: '150px' }}>
                  <InputLabel>{t('location')}</InputLabel>
                  <Select
                    name="location"
                    value={selectedLocation}
                    onChange={handleFilterChange}
                  >
                    <MenuItem value="">{t('all')}</MenuItem>
                    {locations.map((location) => (
                      <MenuItem key={location.id} value={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Paper>
          </Grid>

          {/* Barcode Table */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('select')}</TableCell>
                      <TableCell>{t('barcode')}</TableCell>
                      <TableCell>{t('grossWeight')}</TableCell>
                      <TableCell>{t('netWeight')}</TableCell>
                      <TableCell>{t('location')}</TableCell>
                      <TableCell>{t('category')}</TableCell>
                      <TableCell>{t('actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredBarcodeData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((barcode) => (
                        <TableRow key={barcode.id}>
                          <TableCell>
                            <Checkbox />
                          </TableCell>
                          <TableCell>{barcode.barcode}</TableCell>
                          <TableCell>{barcode.grossWeight}</TableCell>
                          <TableCell>{barcode.netWeight}</TableCell>
                          <TableCell>
                            {locations.find((loc) => loc.id === barcode.location)?.name ||
                              t('unknown')}
                          </TableCell>
                          <TableCell>
                            {categories.find((cat) => cat.id === barcode.category)?.name ||
                              t('unknown')}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="outlined"
                              onClick={() => openModal(barcode)}
                              sx={{ mr: 1 }}
                            >
                              {t('edit')}
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => handleDeleteBarcode(barcode.id)}
                            >
                              {t('delete')}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={filteredBarcodeData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
              />
            </Paper>
          </Grid>
        </Grid>

        {/* Barcode Modal */}
        <BarcodeModal
          isOpen={isModalOpen}
          barcode={selectedBarcode}
          onClose={closeModal}
          categories={categories}
          locations={locations}
          pallets={pallets}
          boxes={boxes}
          stages={stages}
          direction={direction}
          t={t}
        />
      </Box>
    </Container>
  );
};

export default Barcodes;
