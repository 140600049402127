import React, {useState} from 'react';
import {
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
  Avatar
} from '@mui/material';
import { PhotoCamera} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Profile = ({direction}) => {
  const { t } = useTranslation(); // Assuming you are using translation keys
  // user information states
  let [name,setName] = useState('');
  let [email,setEmail] = useState('');
  let [mobile,setMobile] = useState('');
  let [city,setCity] = useState('');
  let [shippingAddress,setShippingAddress] = useState('');
  let [billingAddress,setBillingAddress] = useState('');
  let [website,setWebsite] = useState('');
  let [businessType,setBusinessType] = useState('');
  let [businessIndustry,setBusinessIndustry] = useState('');
  let [profileImage, setProfileImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const renderProfileAvatar = () => {
    if (profileImage) {
      return (
        <Avatar alt="Profile Image" src={profileImage} sx={{ width: 100, height: 100 }} />
      );
    } else {
      return (
        <Avatar sx={{ width: 100, height: 100 }}>
          <PhotoCamera />
        </Avatar>
      );
    }
  };

  return (
    <Container maxWidth="lg"> 
      <Box sx={{ mt: 4, mb: 4 }}>
        <Paper style={{ padding: 20 }}>
          <Typography variant="h5" gutterBottom>
            {t('profileInformation')}
          </Typography>
          {/* Logo Upload Section */}
          <Grid item xs={12}>
              <Typography variant="subtitle1">{t('companyLogo')}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="profile-image-upload"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="profile-image-upload">
                <IconButton color="primary" component="span">
                  {renderProfileAvatar()}
                </IconButton>
              </label>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ margin: '20px 0' }} />
            </Grid>
          <Grid container spacing={2}>

            {/* Password section */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t('passwordChange')}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField 
                InputLabelProps={{
                  className: direction === "rtl" ? 'custom-label-rtl' : ''
                }}
                fullWidth label={t('currentPassword')} defaultValue={null} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField 
                InputLabelProps={{
                  className: direction === "rtl" ? 'custom-label-rtl' : ''
                }}
                fullWidth label={t('newPassword')} defaultValue={null} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField 
                InputLabelProps={{
                  className: direction === "rtl" ? 'custom-label-rtl' : ''
                }}
                fullWidth label={t('newPasswordAgain')} defaultValue={null} />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary">
                {t('changePassword')}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '20px 0' }} />
            </Grid>

            {/* Personal Details Section */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t('personalDetails')}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField 
                InputLabelProps={{
                  className: direction === "rtl" ? 'custom-label-rtl' : ''
                }}
                fullWidth label={t('name')} defaultValue={name} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField 
                InputLabelProps={{
                  className: direction === "rtl" ? 'custom-label-rtl' : ''
                }}
                fullWidth label={t('email')} defaultValue={email} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField 
                InputLabelProps={{
                  className: direction === "rtl" ? 'custom-label-rtl' : ''
                }}
                fullWidth label={t('mobile')} defaultValue={mobile} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField 
                InputLabelProps={{
                  className: direction === "rtl" ? 'custom-label-rtl' : ''
                }}
              fullWidth label={t('city')} defaultValue={city} />
            </Grid>

            {/* Addresses Section */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t('addresses')}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField InputLabelProps={{
                  className: direction === "rtl" ? 'custom-label-rtl' : ''
                }}
                fullWidth label={t('shippingAddress')} multiline rows={3} defaultValue={shippingAddress} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField 
              InputLabelProps={{
                className: direction === "rtl" ? 'custom-label-rtl' : ''
              }}
              fullWidth label={t('billingAddress')} multiline rows={3} defaultValue={billingAddress} />
            </Grid>

            {/* Business Information Section */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t('businessInformation')}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField InputLabelProps={{
                  className: direction === "rtl" ? 'custom-label-rtl' : ''
                }}
                fullWidth label={t('website')} defaultValue={website} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField 
              InputLabelProps={{
                className: direction === "rtl" ? 'custom-label-rtl' : ''
              }}
              disabled fullWidth label={t('businessType')} defaultValue={businessType} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField 
              InputLabelProps={{
                className: direction === "rtl" ? 'custom-label-rtl' : ''
              }}
              disabled fullWidth label={t('businessIndustry')} defaultValue={businessIndustry} />
            </Grid>

            {/* Actions Section */}
            <Grid item xs={12}>
              <Button variant="contained" color="primary">
                {t('saveChanges')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};

export default Profile;