import React, { useState, useContext } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/AuthContext';
import { LanguageContext } from '../../contexts/LanguageContext';

const ProfileMenu = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext); // Access logout from context
  const { toggleLanguage } = useContext(LanguageContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    logout(); // Clear auth state and token
    navigate('/login'); // Redirect to login page
    handleClose();
  };

  const handleProfileClick = () => {
    navigate('/profile'); // Redirect to login page
    handleClose();
  };

  const handleSettingsClick = () => {
    navigate('/settings'); // Redirect to login page
    handleClose();
  };

  const handlePaymentsClick = () => {
    navigate('/payments'); // Redirect to login page
    handleClose();
  };

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="avatar"
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'profile-button',
        }}
      >
        <MenuItem onClick={()=>{handleProfileClick()}}>{t('profile')}</MenuItem>
        <MenuItem onClick={()=>{handlePaymentsClick()}}>{t('payment')}</MenuItem>
        <MenuItem onClick={handleLogoutClick}>{t('logout')}</MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
