import React, { useState, useEffect } from 'react';
import { ReactComponent as BoxSvg } from './assets/images/box-01.svg';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  TextField,
  Button,
  useMediaQuery,
} from '@mui/material';
import { Search, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import APIService from '../../APIService';
import Charts from './components/Charts';
import Battery from './components/Battery';
import AntennaIndicator from './components/AntennaIndicator';
// modals
import PortModal from './modals/EditPortModal';
import EditDeviceModal from './modals/EditDeviceModal';
import BindLocationModal from './modals/BindLocationModal';

const Devices = ({ direction }) => {
  const { t } = useTranslation();
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedDeviceId, setExpandedDeviceId] = useState(null);
  const [expandAll, setExpandAll] = useState(false);
  const [selectedPort, setSelectedPort] = useState(null);
  const [isPortModalOpen, setIsPortModalOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)'); // Check if screen size is mobile
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);

  // Fetch devices and their data
  const fetchDevicesData = async (query = '') => {
    setIsLoading(true);
    try {
      const searchParams = query ? `?query=${encodeURIComponent(query)}` : ''; // Encode query to handle special characters
      const response = await APIService.getUserNodes(searchParams);
      if (!response.error) {
        setDevices(response.nodes || []);
      } else {
        // TODO: handle the error
      }
    } catch (error) {
      console.error('Error fetching devices data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDevicesData();
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    fetchDevicesData(value.trim());
  };

  const toggleDeviceDetails = (deviceId) => {
    setExpandedDeviceId(expandedDeviceId === deviceId ? null : deviceId);
  };

  const toggleAllDevices = () => {
    if (expandAll) {
      setExpandedDeviceId(null);
    } else {
      setExpandedDeviceId('all');
    }
    setExpandAll(!expandAll);
  };

  const handlePortClick = (port) => {
    setSelectedPort(port);
    setIsPortModalOpen(true);
  };

  const handlePortModalClose = () => {
    setIsPortModalOpen(false);
    setSelectedPort(null);
  };

  const handleOpenEditModal = (device) => {
    setSelectedDevice(device);
    setIsEditModalOpen(true);
  };
  
  const handleOpenLocationModal = (device) => {
    setSelectedDevice(device);
    setIsLocationModalOpen(true);
  };
  
  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedDevice(null);
  };
  
  const handleCloseLocationModal = () => {
    setIsLocationModalOpen(false);
    setSelectedDevice(null);
  };

  const formatRelativeTime = (timestamp) => {
    if (!timestamp) return direction === 'rtl' ? 'אף פעם' : 'Never';
  
    const now = Date.now();
    const difference = now - timestamp;
  
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);
  
    const format = (value, unit) => {
      if (direction === 'rtl') {
        // RTL format (e.g., Hebrew): "לפני 5 דקות"
        return `לפני ${value} ${unit}`;
      } else {
        // LTR format (e.g., English): "5 minutes ago"
        return `${value} ${unit} ago`;
      }
    };
  
    if (seconds < 60) return format(seconds, direction === 'rtl' ? 'שניות' : 'seconds');
    if (minutes < 60) return format(minutes, direction === 'rtl' ? 'דקות' : 'minutes');
    if (hours < 24) return format(hours, direction === 'rtl' ? 'שעות' : 'hours');
    if (days < 7) return format(days, direction === 'rtl' ? 'ימים' : 'days');
    if (weeks < 4) return format(weeks, direction === 'rtl' ? 'שבועות' : 'weeks');
    if (months < 12) return format(months, direction === 'rtl' ? 'חודשים' : 'months');
    return format(years, direction === 'rtl' ? 'שנים' : 'years');
  };
  

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} sx={{ mt: 4 }}>

        {/* Search Field */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <TextField
              variant="outlined"
              placeholder={t('searchDevices')}
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: <Search />,
              }}
              InputLabelProps={{
                className: direction === "rtl" ? 'custom-label-rtl' : ''
              }}
              sx={{
                flexGrow: 1,
                mr: 3, // Increase the margin-right for more space
              }}
            />
            <Button
              variant="contained"
              onClick={toggleAllDevices}
              sx={{
                height: '56px', // Match the default height of TextField
              }}
            >
              {expandAll ? t('closeAllDevices') : t('openAllDevices')}
            </Button>
          </Box>
        </Grid>

        {/* Devices Table */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {t('devices')}
            </Typography>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer
                sx={{
                  overflowX: 'auto', // Enable horizontal scrolling for smaller screens
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}></TableCell>
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{t('uid')}</TableCell>
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{t('name')}</TableCell>
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{t('locationName')}</TableCell>
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{t('batteryCapacity')}</TableCell>
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{t('RSSI')}</TableCell>
                      <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{t('lastSeen')}</TableCell>
                      <TableCell>{t('actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devices.map((device) => (
                      <React.Fragment key={device.id}>
                        <TableRow>
                          <TableCell align={direction === 'rtl' ? 'right' : 'left'}>
                            <IconButton size="small" onClick={() => toggleDeviceDetails(device.id)}>
                              {expandAll || expandedDeviceId === device.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                          </TableCell>
                          <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{device.uid}</TableCell>
                          <TableCell align={direction === 'rtl' ? 'right' : 'left'}>{device.name || t('N/A')}</TableCell>
                          <TableCell align={direction === 'rtl' ? 'right' : 'left'}>
                            {device.location === null ? t('N/A') : device.location.name}
                          </TableCell>
                            <TableCell align={direction === 'rtl' ? 'right' : 'left'}>
                              <Battery level={device.battCapacity || 0} />
                            </TableCell>
                            <TableCell align={direction === 'rtl' ? 'right' : 'left'}>
                              <AntennaIndicator rssi={device.rssi || 0} />
                            </TableCell>
                          <TableCell align={direction === 'rtl' ? 'right' : 'left'}>
                            {formatRelativeTime(device.lastSeen) || t('never')}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => handleOpenEditModal(device)}
                            >
                              {t('edit')}
                            </Button>
                            <Button
                              variant="outlined"
                              size="small"
                              sx={{ ml: 1 }}
                              onClick={() => handleOpenLocationModal(device)}
                            >
                              {device.location ? t('editLocation') : t('bindLocation')}
                            </Button>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={7}
                          align="center" // Aligns content to the center of the table cell
                        >
                          <Collapse
                            in={expandAll || expandedDeviceId === device.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                height: 'auto',                      
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              {/* Chart */}
           
                              <Charts node={device} />

                              {/* SVG + Ports */}
                              {!isMobile   && (
                                <Box
                                  sx={{
                                    flex: 1,
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '30%',
                                    maxWidth: '30%',
                                  }}
                                >
                                  <BoxSvg
                                    style={{
                                      width: '50%',
                                      maxWidth: '50%',
                                      height: 'auto',
                                      margin: '0 auto',
                                    }}
                                  />
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      gap: 1,
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      mt: 2,
                                    }}
                                  >
                                    {(direction === 'rtl' ? [...device.ports].reverse() : device.ports).map((port) => {
                                      const isAvailable = port.connected;
                                      if(isAvailable === undefined){
                                        return (<></>);
                                      }
                                      return (
                                        <Box
                                          key={port.uid}
                                          sx={{
                                            width: 50,
                                            height: 50,
                                            backgroundColor: isAvailable ? 'rgb(76, 175, 80)' : 'rgba(0, 0, 0, 0.1)',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '1px solid #ccc',
                                            cursor: isAvailable ? 'pointer' : 'not-allowed',
                                          }}
                                          onClick={isAvailable ? () => handlePortClick(port) : undefined}
                                        >
                                          {port.uid}
                                        </Box>
                                      );
                                    })}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>

                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Port Modal */}
      <PortModal
        open={isPortModalOpen}
        onClose={handlePortModalClose}
        port={selectedPort}
        sensors={selectedPort?.sensors || []}
        t={t}
        direction={direction}
      />

      {/* Edit Device Modal */}
      <EditDeviceModal
        open={isEditModalOpen}
        onClose={handleCloseEditModal}
        device={selectedDevice}
        t={t}
        direction={direction}
      />

      {/* Bind/Edit Location Modal */}
      <BindLocationModal
        open={isLocationModalOpen}
        onClose={handleCloseLocationModal}
        device={selectedDevice}
        t={t}
        direction={direction}
      />

    </Container>
  );
};

export default Devices;