import api from './APIService';

export const getLocations = async () => {
  const response = await api.get(`/locations`);
  return response.data;
};

export const createLocation = async (location) => {
  const response = await api.put(`/locations`, location);
  return response.data;
};

export const updateLocation = async (location) => {
  const response = await api.post(`/locations/${location.id}`, location);
  return response.data;
};

export const getLocationByID = async (id) => {
    const response = await api.get(`/locations/${id}`);
    return response.data;
};

export const bindBoundsToLocation = async (bounds, locationId) => {
  try {
    const response = await api.post(`/locations/${locationId}/bind-bounds`, {
      bounds,
    });
    return response.data;
  } catch (error) {
    console.error('Error binding bounds to location:', error);
    throw error;
  }
};