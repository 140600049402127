import React, { useState } from 'react';
import {
  Modal,
  Paper,
  Typography,
  TextField,
  Button,
} from '@mui/material';

const EditDeviceModal = ({
  open,
  onClose,
  device,
  onSave,
  t,
  direction
}) => {
  // Local state to manage form inputs
  const [formData, setFormData] = useState({
    name: device?.name || '',
    updateInterval: device?.updateInterval || 0,
  });

  // Handle input change
  const handleChange = (field) => (event) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  // Handle save action
  const handleSave = () => {
    onSave(formData);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={{ p: 4, maxWidth: 500, margin: 'auto', mt: 10 }}>
        <Typography variant="h6" sx={{ mb: 2 }} InputLabelProps={{
                className: direction === "rtl" ? 'custom-label-rtl' : ''
              }}>
          {t('editDevice')}
        </Typography>

        {/* Device Name Field */}
        <TextField
          fullWidth
          label={t('deviceName')}
          value={formData.name}
          onChange={handleChange('name')}
          variant="outlined"
          sx={{ mb: 2 }}
          InputLabelProps={{
            className: direction === "rtl" ? 'custom-label-rtl' : ''
          }}
        />

        {/* Update Interval Field */}
        <TextField
          fullWidth
          label={t('updateIntervalMin')}
          value={formData.updateInterval}
          onChange={handleChange('updateInterval')}
          variant="outlined"
          type="number"
          sx={{ mb: 3 }}
          InputLabelProps={{
            className: direction === "rtl" ? 'custom-label-rtl' : ''
          }}
        />

        {/* Action Buttons */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={handleSave}>
            {t('save')}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default EditDeviceModal;
