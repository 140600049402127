import React from 'react';
import { Modal, Paper, Typography, TextField, Button } from '@mui/material';

const AddAccountModal = ({ open, onClose, onSave }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={{ p: 4, maxWidth: 500, margin: 'auto', mt: 10 }}>
        <Typography variant="h6" gutterBottom>Add Account</Typography>
        <TextField label="Name" fullWidth sx={{ mb: 2 }} />
        <TextField label="CRM ID" fullWidth sx={{ mb: 2 }} />
        <TextField label="TM Tag" fullWidth sx={{ mb: 2 }} />
        <TextField label="Latitude" fullWidth sx={{ mb: 2 }} />
        <TextField label="Longitude" fullWidth sx={{ mb: 2 }} />
        <Button variant="contained" fullWidth onClick={onSave}>Save</Button>
      </Paper>
    </Modal>
  );
};

export default AddAccountModal;
