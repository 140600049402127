import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Tabs,
  Tab,
} from '@mui/material';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { options as initialOptions } from '../configs/chartsOptions';
import APIService from '../../../APIService';

const ApexCharts = ({ node, direction }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(0); // Tab index
  const [groupedSensors, setGroupedSensors] = useState({});
  const [selectedType, setSelectedType] = useState(null); // Sensor type for the selected tab
  const [sensorVisibility, setSensorVisibility] = useState({});
  const [dateRange, setDateRange] = useState({
    from: new Date(Date.now() - 24 * 60 * 60 * 1000),
    to: new Date(),
  });
  const [chartLoading, setChartLoading] = useState(false);
  const [selectedButton, setSelectedButton] = useState(() => {
    return localStorage.getItem('selectedButton') || '24hours';
  });

  const fetchData = useCallback(
    async (customRange) => {
      const range = customRange || dateRange;
      try {
        const { from, to } = range;

        const params = {
          nodeId: node.id,
          from: from.getTime(),
          to: to.getTime(),
        };

        setChartLoading(true);
        const response = await APIService.getDataByNode(params);

        if (response.error) {
          console.error('Error fetching data:', response.message);
          return;
        }

        const dataSample = response;

        const grouped = dataSample.sensors.reduce((acc, sensor) => {
          if (!acc[sensor.type]) {
            acc[sensor.type] = [];
          }
          acc[sensor.type].push(sensor);
          return acc;
        }, {});

        setGroupedSensors(grouped);

        const initialVisibility = dataSample.sensors.reduce((acc, sensor) => {
          acc[sensor.id] = true;
          return acc;
        }, {});
        setSensorVisibility(initialVisibility);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setChartLoading(false);
      }
    },
    [dateRange, node.id]
  );

  useEffect(() => {
    const fetchControllerCharts = async () => {
      await fetchData();
      setLoading(false);
    };

    fetchControllerCharts();
  }, [fetchData]);

  useEffect(() => {
    const sensorTypes = Object.keys(groupedSensors);
    if (sensorTypes.length > 0 && !selectedType) {
      setSelectedType(sensorTypes[0]); // Set the first sensor type as default
    }
  }, [groupedSensors, selectedType]);

  const handleDateRangeChange = (key, newValue) => {
    setDateRange((prev) => ({
      ...prev,
      [key]: newValue,
    }));
    fetchData({ ...dateRange, [key]: newValue });
  };

  const handleQuickRange = (days, label) => {
    const newRange = {
      from: new Date(Date.now() - days * 24 * 60 * 60 * 1000),
      to: new Date(),
    };
    setDateRange(newRange);
    fetchData(newRange);

    setSelectedButton(label);
    localStorage.setItem('selectedButton', label);
  };

  const renderSeriesData = (type) => {
    const sensorsOfType = groupedSensors[type] || [];
    return sensorsOfType
      .filter((sensor) => sensorVisibility[sensor.id])
      .map((sensor) => ({
        name: `${t('sensor')} ${sensor.port}`,
        data: sensor.data.map((point) => ({
          x: parseInt(point.timestamp, 10),
          y: point.value,
        })),
      }));
  };

  const renderSensorToggles = (selectedSensor) => (
    <Box
      key={selectedSensor.id}
      sx={{
        width: 150,
        height: 100,
        border: '1px solid #ccc',
        borderRadius: 2,
        padding: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        boxShadow: 1,
      }}
    >
      <Box
        sx={{
          width: 30,
          height: 30,
          backgroundColor: '#f0f0f0',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <VerticalAlignBottomIcon />
      </Box>
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
        {t('sensor')} {selectedSensor.port} {t('depth')}: {selectedSensor.depth || t('unknown')}
      </Typography>
      <label>
        <input
          type="checkbox"
          checked={sensorVisibility[selectedSensor.id]}
          onChange={() => toggleSensorVisibility(selectedSensor.id)}
        />
        {t('toggleVisibility')}
      </label>
    </Box>
  );

  const toggleSensorVisibility = (sensorId) => {
    setSensorVisibility((prev) => ({
      ...prev,
      [sensorId]: !prev[sensorId],
    }));
  };

  const renderChart = (type) => {
    if (!type) {
      return null;
    }

    const chartOptions = { ...initialOptions };

    return (
      <Box sx={{ position: 'relative', width: '100%' }}>
        {chartLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <ReactApexChart
          style={{ marginTop: '10px' }}
          options={chartOptions}
          series={renderSeriesData(type)}
          type="line"
          width={'100%'}
        />
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            flexWrap: 'wrap',
          }}
        >
          {groupedSensors[type]?.map((sensor) => renderSensorToggles(sensor))}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ width: '100%', p: 2, position: 'relative' }}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item>
            <DatePicker
              label={t('fromDate')}
              value={dateRange.from}
              onChange={(newValue) => handleDateRangeChange('from', newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label={t('toDate')}
              value={dateRange.to}
              onChange={(newValue) => handleDateRangeChange('to', newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
        {['24hours', '7days', '30days', '3months'].map((label) => (
          <Button
            key={label}
            variant={selectedButton === label ? 'contained' : 'outlined'}
            onClick={() =>
              handleQuickRange(
                label === '24hours' ? 1 : label === '7days' ? 7 : label === '30days' ? 30 : 90,
                label
              )
            }
          >
            {t(label)}
          </Button>
        ))}
      </Box>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Tabs
          value={value}
          onChange={(e, newValue) => {
            setValue(newValue);
            const sensorType = Object.keys(groupedSensors)[newValue];
            setSelectedType(sensorType);
          }}
          centered
        >
          {Object.keys(groupedSensors).map((type, index) => (
            <Tab key={index} label={<Typography variant="body1">{t(type)}</Typography>} />
          ))}
        </Tabs>
      </Box>

      {selectedType && renderChart(selectedType)}
    </Box>
  );
};

export default ApexCharts;
