import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Modal,
} from '@mui/material';
import { updateLocation, createLocation } from '../../../services/locationService';

const LocationModal = ({ open, onClose, onSave, location, setLocation, editingLocation, direction, t }) => {
  const handleChange = (field, value) => {
    setLocation((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    try {
      if (editingLocation) {
        // Logic for updating an existing location
        const response = await updateLocation(location); // Call the updateLocation API
        if (!response.error) {
          onSave(response.location); // Pass updated location to parent
          onClose(); // Close the modal
        } else {
          console.error('Failed to update location:', response?.message || 'Unknown error');
        }
      } else {
        // Logic for creating a new location
        const response = await createLocation(location); // Call the createLocation API
        if (!response.error) {
          onSave(response.location); // Pass the newly created location to parent
          onClose(); // Close the modal
        } else {
          console.error('Failed to create location:', response?.message || 'Unknown error');
        }
      }
    } catch (error) {
      console.error('Error saving location:', error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          {editingLocation ? t('editLocation') : t('addNewLocation')}
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            InputLabelProps={{
              className: direction === 'rtl' ? 'custom-label-rtl' : '',
            }}
            label={t('locationName')}
            value={location.name || ''}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            InputLabelProps={{
              className: direction === 'rtl' ? 'custom-label-rtl' : '',
            }}
            label={t('landSize')}
            value={location.sizeDunam || ''}
            onChange={(e) => handleChange('sizeDunam', e.target.value)}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>{t('locationType')}</InputLabel>
          <Select
            value={location.type || ''}
            onChange={(e) => handleChange('type', e.target.value)}
            label={t('locationType')}
          >
            <MenuItem value="greenhouse">{t('greenhouse')}</MenuItem>
            <MenuItem value="openfield">{t('openfield')}</MenuItem>
            <MenuItem value="indoor">{t('indoor')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <TextField
            InputLabelProps={{
              className: direction === 'rtl' ? 'custom-label-rtl' : '',
            }}
            label={t('locationDetails')}
            value={location.description || ''}
            onChange={(e) => handleChange('description', e.target.value)}
          />
        </FormControl>
        <Box sx={{ textAlign: 'right' }}>
          <Button onClick={handleSave} variant="contained" color="primary">
            {t(editingLocation ? 'saveChanges' : 'addLocation')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LocationModal;
