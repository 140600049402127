import api from './APIService';

export const getAllAccounts = async (accountName) => {
  const query = accountName ? `?account=${encodeURIComponent(accountName)}` : '';
  const response = await api.get(`/admin/accounts${query}`);
  return response.data;
};

export const getAllUsersUnderAccount = async (accountId) => {
  const response = await api.get(`/admin/account/${accountId}/users`);
  return response.data;
};

export const updateUser = async (userId, userData) => {
    try {
      const response = await api.put(`/admin/users/${userId}`, userData);
      return response.data; // Return the response data from the API
    } catch (error) {
      console.error('Error updating user:', error);
      throw error; // Rethrow the error for further handling by the caller
    }
};

export const createUser = async (userData) => {
    try {
      const response = await api.post('/admin/users', userData);
      return response.data; // Return the response data from the API
    } catch (error) {
      console.error('Error creating user:', error);
      throw error; // Rethrow the error for further handling by the caller
    }
};