import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { getProduceCategories, destroyProduceCategory} from '../../../services/produceService';
import CategoryModal from '../modals/CategoryModal'; // Import the modal component

const Categories = ({ direction }) => {
  const { t } = useTranslation();
  const [categoryData, setCategoryData] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getProduceCategories();
        if (response.categories) {
          setCategoryData(response.categories || []);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleAddCategory = () => {
    setCurrentCategory(null);
    setIsModalOpen(true);
  };

  const handleEditCategory = (category) => {
    setCurrentCategory(category);
    setIsModalOpen(true);
  };

  const handleDeleteCategory = async (categoryId) => {
    const deletedCategory = await destroyProduceCategory(categoryId);
    if(!deletedCategory.error){
        setCategoryData((prevData) => prevData.filter((category) => category.id !== categoryId));
    }else{
        // TODO: handle error
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentCategory(null);
  };

  const handleSaveCategory = () => {
    if (currentCategory?.id) {
      setCategoryData((prevData) =>
        prevData.map((category) =>
          category.id === currentCategory.id ? currentCategory : category
        )
      );
    } else {
      setCategoryData((prevData) => [
        ...prevData,
        { ...currentCategory, id: prevData.length + 1 },
      ]);
    }
    handleModalClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const csvHeaders = [
    { label: t('id'), key: 'id' },
    { label: t('name'), key: 'name' },
    { label: t('description'), key: 'description' },
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t('categoryManagement')}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleAddCategory} sx={{ mb: 2 }}>
          {t('addCategory')}
        </Button>
        <Button variant="contained" color="secondary" sx={{ mb: 2, ml: 2 }}>
          <CSVLink
            data={categoryData}
            headers={csvHeaders}
            filename={`categories.csv`}
            style={{ textDecoration: 'none', color: 'white' }}
          >
            {t('exportCSV')}
          </CSVLink>
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('id')}</TableCell>
                <TableCell>{t('name')}</TableCell>
                <TableCell>{t('description')}</TableCell>
                <TableCell>{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categoryData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((category) => (
                  <TableRow key={category.id}>
                    <TableCell>{category.id}</TableCell>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>{category.description}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleEditCategory(category)}
                        sx={{ mr: 1 }}
                      >
                        {t('edit')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteCategory(category.id)}
                      >
                        {t('delete')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={categoryData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <CategoryModal
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
        handleSave={handleSaveCategory}
        direction={direction}
        t={t}
      />
    </Container>
  );
};

export default Categories;
