import axios from 'axios';

//const API_BASE_URL = 'http://localhost:1337/api/v2/';
const API_BASE_URL = 'https://cloud.omniot.io/api/v2/';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;