import api from './APIService';

export const getProduceBarcodes = async () => {
    const response = await api.get(`/produce`);
    return response.data;
};

export const postProduceBarcode = async (barcode) => {
    const response = await api.post(`/produce/${barcode.id}`,barcode);
    return response.data;
};

export const putProduceBarcode = async (barcode) => {
    const response = await api.put(`/produce`,barcode);
    return response.data;
};

export const destroyProduceBarcode = async (barcodeId) => {
    const response = await api.delete(`/produce/${barcodeId}`);
    return response.data;
};

// boxes

export const getProduceBoxes = async () => {
  const response = await api.get(`/produce/boxes`);
  return response.data;
};

export const postProduceBox = async (box) => {
    const response = await api.post(`/produce/boxes/${box.id}`,box);
    return response.data;
};

export const putProduceBox = async (box) => {
    const response = await api.put(`/produce/boxes`,box);
    return response.data;
};

export const destroyProduceBox = async (boxId) => {
    const response = await api.delete(`/produce/boxes/${boxId}`);
    return response.data;
};

// pallets 

export const postProducePallet = async (pallet) => {
    const response = await api.post(`/produce/pallets/${pallet.id}`,pallet);
    return response.data;
};

export const putProducePallet = async (pallet) => {
    const response = await api.put(`/produce/pallets`,pallet);
    return response.data;
};

export const destroyProductPallet = async (palletId) => {
    const response = await api.delete(`/produce/pallets/${palletId}`);
    return response.data;
};

export const getProducePallets = async () => {
  const response = await api.get(`/produce/pallets`);
  return response.data;
};

// stages

export const getProduceStages = async () => {
    const response = await api.get(`/produce/stages`);
    return response.data;
};

export const postProduceStage = async (stage) => {
    const response = await api.post(`/produce/stages/${stage.id}`,stage);
    return response.data;
};

export const putProduceStage = async (stage) => {
    const response = await api.put(`/produce/stages`,stage);
    return response.data;
};

export const destroyProduceStage = async (stageId) => {
    const response = await api.delete(`/produce/stages/${stageId}`);
    return response.data;
};

// categories

export const getProduceCategories = async () => {
    const response = await api.get(`/produce/categories`);
    return response.data;
};

export const postProduceCategory = async (category) => {
    const response = await api.post(`/produce/categories/${category.id}`,category);
    return response.data;
};

export const putProduceCategory = async (category) => {
    const response = await api.put(`/produce/categories`,category);
    return response.data;
};

export const destroyProduceCategory = async (categoryId) => {
    const response = await api.delete(`/produce/categories/${categoryId}`);
    return response.data;
};
