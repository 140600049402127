import React, { useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Rectangle, FeatureGroup } from 'react-leaflet';
import { Box, Paper, Grid, Button, Select, MenuItem, Typography, FormControl, InputLabel } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from 'react-leaflet-draw';
import { useTranslation } from 'react-i18next';
import {bindBoundsToLocation} from '../../../services/locationService';

import '../../../assets/css/Map.css';

const Map = ({ MapWidth, createdCallback, initialPosition, locations}) => {
  const { t } = useTranslation(); // Assuming you are using translation keys
  const [selectedRegion, setSelectedRegion] = useState(null); // Track the bounds of the selected region
  const [showPopup, setShowPopup] = useState(false); // Toggle popup visibility
  const [selectedLocation, setSelectedLocation] = useState(''); // Track selected location for binding
  const [fields, setFields] = useState(locations)

  const layerRef = useRef(null); // Reference to the last drawn layer

  const handleCreated = (e) => {
    const { layerType, layer } = e;
    if (layerType === 'rectangle') {
      const bounds = layer.getBounds();
      setSelectedRegion(bounds);
      setShowPopup(true);
      layerRef.current = layer; // Store the reference to the drawn layer
    }
    createdCallback(e);
  };

  const handleBindToLocation = async () => {
    if (selectedRegion && selectedLocation) {
      const bounds = [
        [selectedRegion.getSouthWest().lat, selectedRegion.getSouthWest().lng],
        [selectedRegion.getNorthEast().lat, selectedRegion.getNorthEast().lng],
      ];
  
      try {
        const updatedLocation = await bindBoundsToLocation(bounds, selectedLocation);
        console.log('Updated Location:', updatedLocation);
  
        // Update local state if needed
        const updatedFields = fields.map((field) =>
          field.id === selectedLocation ? { ...field, bounds } : field
        );
        setFields(updatedFields);
  
        handleCancel();
      } catch (error) {
        alert('Failed to bind bounds to location.');
      }
    }
  };

  const handleCancel = () => {
    if (layerRef.current) {
      layerRef.current.remove(); // Remove the drawn layer from the map
    }
    setSelectedRegion(null);
    setShowPopup(false);
    setSelectedLocation('');
  };

  return (
    <Grid item xs={12} md={MapWidth}>
      <Paper elevation={3} sx={{ p: 2, height: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ height: '80vh', width: '100%' }}>
            <MapContainer
              attributionControl={false}
              center={initialPosition}
              zoom={17}
              scrollWheelZoom={true}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
              />
              <FeatureGroup>
                <EditControl
                  position="topright"
                  onCreated={handleCreated}
                  draw={{
                    rectangle: true,
                    polyline: true,
                    polygon: true,
                    circle: false,
                    marker: false,
                    circlemarker: false,
                  }}
                />
                {fields.map((field) => (
                  field.bounds.length > 0 && ( // Only render if bounds are not empty
                    <Rectangle
                      key={field.id}
                      bounds={field.bounds}
                      color="blue"
                      fillOpacity={0.5}
                    >
                      <Popup>
                        {t('locationName')}: {field.name}
                      </Popup>
                    </Rectangle>
                  )
                ))}
              </FeatureGroup>
              {showPopup && selectedRegion && (
                <Popup
                  position={[
                    selectedRegion.getCenter().lat,
                    selectedRegion.getCenter().lng,
                  ]}
                  onClose={handleCancel}
                >
                  <Box sx={{ textAlign: 'center', minWidth: '200px' }}>
                    <Typography sx={{ mb: 2 }}>{t('bindToLocation')}</Typography>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel>{t('selectLocation')}</InputLabel>
                      <Select
                        value={selectedLocation}
                        onChange={(e) => setSelectedLocation(e.target.value)}
                        label={t('selectLocation')}
                      >
                        {fields.map((field) => (
                          <MenuItem key={field.id} value={field.id}>
                            {field.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleCancel}
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleBindToLocation}
                        disabled={!selectedLocation}
                      >
                        {t('bind')}
                      </Button>
                    </Box>
                  </Box>
                </Popup>
              )}
            </MapContainer>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default Map;
