import React, { useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Boxes from './sections/Boxes';
import Categories from './sections/Categories';
import Pallets from './sections/Pallets';
import Stages from './sections/Stages';

const WarehouseSettings = ({ direction }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <Boxes direction={direction} />;
      case 1:
        return <Categories direction={direction} />;
      case 2:
        return <Pallets direction={direction} />;
      case 3:
        return <Stages direction={direction} />;
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t('settings')}
        </Typography>
        <Paper>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={t('boxes')} />
            <Tab label={t('categories')} />
            <Tab label={t('pallets')} />
            <Tab label={t('stages')} />
          </Tabs>
        </Paper>
        <Box sx={{ mt: 3 }}>{renderTabContent()}</Box>
      </Box>
    </Container>
  );
};

export default WarehouseSettings;