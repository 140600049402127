import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Card, CardContent, Button, CircularProgress, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ControllersList = ({ controllers = [], onControllerSelect, direction }) => {
  const { t } = useTranslation();
  const [selectedState, setSelectedState] = useState(true); // Default: normal (true)
  const [filteredControllers, setFilteredControllers] = useState([]); // State for filtered controllers
  const [loading, setLoading] = useState(false); // Loading state
  const [selectedControllerId, setSelectedControllerId] = useState(null); // Track selected controller
  const [locations, setLocations] = useState([]); // Locations state
  const [selectedLocation, setSelectedLocation] = useState('all');

  // Extract unique locations from controllers
  useEffect(() => {
    const fetchLocations = () => {
      const uniqueLocations = [
        { id: 'all', name: t('allLocations') }, // Add 'all' option
        ...Array.from(
          new Map(
            controllers
              .filter((controller) => controller.location) // Filter controllers with a location
              .map((controller) => [
                controller.location.id, 
                { id: controller.location.id, name: controller.location.name },
              ])
          ).values()
        ),
      ];
      setLocations(uniqueLocations);
    };
    fetchLocations();
  }, [controllers, t]);

  // Update filteredControllers whenever controllers, selectedState, or selectedLocation changes
  useEffect(() => {
    const filtered = controllers.filter((controller) => {
      const matchesState = controller.normal === selectedState;
      const matchesLocation =
        selectedLocation === 'all' ||
        (controller.location && controller.location.id.toString() === selectedLocation);
      return matchesState && matchesLocation;
    });
    setFilteredControllers(filtered);
  }, [controllers, selectedState, selectedLocation]);

  const handleControllerClick = async (id) => {
    if (loading) return; // Prevent handling clicks while loading
  
    setLoading(true); // Show the loading spinner
  
    try {
      if (id !== selectedControllerId) {
        await onControllerSelect(id); // Load new controller data
        setSelectedControllerId(id); // Update the selected controller ID
      }
    } catch (error) {
      console.error('Error loading controller data:', error); // Handle errors gracefully
    } finally {
      setLoading(false); // Hide the loading spinner
    }
  };
  

  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={3} sx={{ height: 'calc(70vh)', overflowY: 'auto', p: 2 }}>
        {/* Location Dropdown */}
        <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel
          className={direction === 'rtl' ? 'custom-label-rtl' : ''}
          id="location-select-label"
        >
          {t('selectLocation')}
        </InputLabel>          
          <Select
            labelId="location-select-label"
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            fullWidth
          >
            {locations.map((location) => (
              <MenuItem key={location.id} value={location.id.toString()}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* State Toggle Buttons */}
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant={selectedState ? 'contained' : 'outlined'}
              onClick={() => setSelectedState(true)}
            >
              {t('normal')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant={!selectedState ? 'contained' : 'outlined'}
              onClick={() => setSelectedState(false)}
            >
              {t('abnormal')}
            </Button>
          </Grid>
        </Grid>

        {/* Loading Animation */}
        {loading && (
          <Grid container justifyContent="center" sx={{ mb: 2 }}>
            <CircularProgress />
          </Grid>
        )}

        {/* Controller List */}
        {!loading && (
          <Grid container spacing={2}>
            {filteredControllers.length > 0 ? (
              filteredControllers.map((controller) => (
                <Grid item xs={12} key={controller.id}>
                  <Card
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 1,
                      flexWrap: 'wrap', // Allow wrapping of child elements
                      gap: 1, // Add spacing between items when wrapped
                    }}
                  >
                    {/* Name and Location */}
                    <CardContent sx={{ flex: 1, minWidth: 0 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          overflow: 'hidden',
                          whiteSpace: 'normal', // Allow wrapping
                          wordWrap: 'break-word', // Break words if needed
                        }}
                      >
                        {controller.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          overflow: 'hidden',
                          whiteSpace: 'normal', // Allow wrapping
                          wordWrap: 'break-word', // Break words if needed
                        }}
                      >
                        {t('location')}: {controller.location?.name || t('unknown')}
                      </Typography>
                    </CardContent>

                    {/* Battery Information */}
                    <CardContent sx={{ textAlign: 'center', flexShrink: 0 }}>
                      <Typography variant="body2" color="textSecondary">
                        {t('battery')}
                      </Typography>
                      <Typography variant="subtitle1">{controller.battCapacity || 0}%</Typography>
                    </CardContent>

                    {/* View Button */}
                    <CardContent sx={{ flexShrink: 0 }}>
                      <Button
                        variant={selectedControllerId === controller.id ? 'contained' : 'outlined'}
                        onClick={() => handleControllerClick(controller.id)}
                        sx={{ whiteSpace: 'nowrap' }} // Prevent wrapping for the button text
                      >
                        {t('view')}
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography variant="body2" sx={{ textAlign: 'center', mt: 2 }}>
                {t('noControllers')}
              </Typography>
            )}
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default ControllersList;
