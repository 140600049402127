import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
} from '@mui/material';
import Map from './components/Map';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getLocations } from '../../services/locationService';
import LocationModal from './modals/LocationModal';
import ConfirmDeleteModal from './modals/ConfirmDeleteModal'; // Import modal

const Locations = ({ direction }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false); // State for delete modal
  const [locationToDelete, setLocationToDelete] = useState(null); // Track location to delete
  const [editingLocation, setEditingLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [location, setLocation] = useState({ name: '', sizeDunam: '', type: '', description: '' });

  const fetchLocationsData = async () => {
    setIsLoading(true);
    try {
      const response = await getLocations();
      if (!response.error) {
        setLocations(response.locations || []);
        setCoordinates(response.coordinates || [31.7680463, 34.778182]);
      } else {
        // Handle error
      }
    } catch (error) {
      console.error('Error fetching locations data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLocationsData();
  }, []);

  const handleOpen = () => {
    setLocation({ name: '', sizeDunam: '', type: '', description: '' });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingLocation(null);
  };

  const handleSaveLocation = async (newLocation) => {
    try {
      await fetchLocationsData(); // Re-fetch locations
      setOpen(false);
      setEditingLocation(null);
    } catch (error) {
      console.error('Error updating locations:', error);
    }
  };

  const handleEditLocation = (id) => {
    const locationToEdit = locations.find((loc) => loc.id === id);
    if (locationToEdit) {
      setEditingLocation(locationToEdit);
      setLocation(locationToEdit); // Populate state with location data
      setOpen(true); // Open modal
    }
  };

  const confirmDeleteLocation = (id) => {
    setLocationToDelete(locations.find((loc) => loc.id === id)); // Set the location to delete
    setDeleteModalOpen(true); // Open the confirmation modal
  };

  const handleDeleteConfirmed = async () => {
    try {
      if (locationToDelete) {
        // Perform deletion logic here
        setLocations((prev) => prev.filter((loc) => loc.id !== locationToDelete.id));
        setDeleteModalOpen(false); // Close modal
        setLocationToDelete(null); // Clear selected location
      }
    } catch (error) {
      console.error('Error deleting location:', error);
    }
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Button variant="contained" color="primary" onClick={handleOpen} style={{ marginBottom: '2%' }}>
              {t('addNewLocation')}
            </Button>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('locationName')}</TableCell>
                    <TableCell>{t('locationType')}</TableCell>
                    <TableCell>{t('locationDetails')}</TableCell>
                    <TableCell>{t('actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {locations.map((loc) => (
                    <TableRow key={loc.id} style={{ cursor: 'pointer' }}>
                      <TableCell>{loc.name}</TableCell>
                      <TableCell>{loc.type}</TableCell>
                      <TableCell>{loc.description}</TableCell>
                      <TableCell>
                        <IconButton aria-label="edit" onClick={() => handleEditLocation(loc.id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => confirmDeleteLocation(loc.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={8}>
            {coordinates.length > 0 ? (
              <Map
                createdCallback={(e) => console.log('Drawing:', e)}
                MapWidth={12}
                initialPosition={coordinates}
                locations={locations}
              />
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            )}
          </Grid>
          <LocationModal
            open={open}
            onClose={handleClose}
            onSave={handleSaveLocation}
            location={location}
            setLocation={setLocation}
            editingLocation={editingLocation}
            direction={direction}
            t={t}
          />
          <ConfirmDeleteModal
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            onConfirm={handleDeleteConfirmed}
            locationName={locationToDelete?.name || ''}
          />
        </Grid>
      )}
    </Box>
  );
};

export default Locations;
