import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { destroyProduceStage, getProduceStages } from '../../../services/produceService';
import StageModal from '../modals/StageModal'; // Import the modal component

const Stages = ({ direction }) => {
  const { t } = useTranslation();
  const [stageData, setStageData] = useState([]);
  const [currentStage, setCurrentStage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const fetchStages = async () => {
      try {
        const response = await getProduceStages();
        if (response.stages) {
          setStageData(response.stages || []);
        }
      } catch (error) {
        console.error('Error fetching stages:', error);
      }
    };

    fetchStages();
  }, []);

  const handleAddStage = () => {
    setCurrentStage(null);
    setIsModalOpen(true);
  };

  const handleEditStage = (stage) => {
    setCurrentStage(stage);
    setIsModalOpen(true);
  };

  const handleDeleteStage = async (stageId) => {
    const response = await destroyProduceStage(stageId);
    if(!response.error){
        setStageData((prevData) => prevData.filter((stage) => stage.id !== stageId));
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentStage(null);
  };

  const handleSaveStage = () => {
    if (currentStage?.id) {
      setStageData((prevData) =>
        prevData.map((stage) => (stage.id === currentStage.id ? currentStage : stage))
      );
    } else {
      setStageData((prevData) => [
        ...prevData,
        { ...currentStage, id: prevData.length + 1 },
      ]);
    }
    handleModalClose();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const csvHeaders = [
    { label: t('id'), key: 'id' },
    { label: t('name'), key: 'name' },
    { label: t('description'), key: 'description' },
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t('stageManagement')}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleAddStage} sx={{ mb: 2 }}>
          {t('addStage')}
        </Button>
        <Button variant="contained" color="secondary" sx={{ mb: 2, ml: 2 }}>
          <CSVLink
            data={stageData}
            headers={csvHeaders}
            filename={`stages.csv`}
            style={{ textDecoration: 'none', color: 'white' }}
          >
            {t('exportCSV')}
          </CSVLink>
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('id')}</TableCell>
                <TableCell>{t('name')}</TableCell>
                <TableCell>{t('description')}</TableCell>
                <TableCell>{t('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stageData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((stage) => (
                  <TableRow key={stage.id}>
                    <TableCell>{stage.id}</TableCell>
                    <TableCell>{stage.name}</TableCell>
                    <TableCell>{stage.description}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleEditStage(stage)}
                        sx={{ mr: 1 }}
                      >
                        {t('edit')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteStage(stage.id)}
                      >
                        {t('delete')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={stageData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <StageModal
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        currentStage={currentStage}
        setCurrentStage={setCurrentStage}
        handleSave={handleSaveStage}
        direction={direction}
        t={t}
      />
    </Container>
  );
};

export default Stages;
